<template>
  <b-card>
    <form-component :discount-code="discountCode" :is-create-view="true" @onSubmit="onSubmit" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/discount-codes/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { create } from '@/http/sales/discount-codes';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      discountCode: {
        code: null,
        value: null,
        email: null,
        type: {
          value: null,
          name: null,
        },
        amount: 0,
        expired_date: null,
        is_active: false,
      },
    };
  },

  methods: {
    async onSubmit(data) {
      try {
        await create(data);

        this.showSuccessNotification('Dane zapisane', 'Kod rabatowy został pomyślnie dodany.');
        this.$router.push({ name: 'discount-codes-index' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas dodawania kodu rabatowego. Skontaktuj się ze swoim developerem.';
        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
